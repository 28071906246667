import React from "react"
import Logo from "./logo"
import { FontAwesomeIcon  } from "@fortawesome/react-fontawesome"

const Footer = () => (
  <footer className="py-5 bg-dark">
    <div className="container">
        <div className="row mb-5">
            <div className="col-md-4 mb-5 fill-white"><Logo /></div> 
            <div className="col-md-8">
                <div className="row">
                    <div className="col-md-6 mb-4">
                        <a className="text-white" href="https://www.google.com/maps/@46.6061374,14.0293304,17z">
                            <div className="row">
                                <div className="col-sm-auto"><FontAwesomeIcon icon="map-marker-alt" size="2x"/></div>
                                <div className="col-sm d-flex align-items-center">Lobisserweg 28 <br/> 9220 Velden am Wörthersee</div>
                            </div>
                        </a>
                    </div>
                    <div className="col-md-6 mb-4">
                        <a className="text-white" href="mailto:office@fs-soft.at">
                            <div className="row">
                                <div className="col-sm-auto"><FontAwesomeIcon icon="envelope" size="2x"/></div>
                                <div className="col-sm d-flex align-items-center">office@fs-soft.at</div>
                            </div>
                        </a>
                    </div>
                    <div className="col-md-6 mb-4">
                        <a className="text-white" href="tel:+43427440888">
                            <div className="row">
                                <div className="col-sm-auto"><FontAwesomeIcon icon="phone" flip="horizontal" size="2x"/></div>
                                <div className="col-sm d-flex align-items-center">+43(0) 4274 / 40 888</div>
                            </div>
                        </a>
                    </div>
                    <div className="col-md-6 mb-4">
                        <a className="text-white" href="https://www.fs-soft.net/support/">
                            <div className="row">
                                <div className="col-sm-auto"><FontAwesomeIcon icon="comments" flip="horizontal" size="2x"/></div>
                                <div className="col-sm d-flex align-items-center">Support</div>
                            </div>
                        </a>
                    </div>
                </div>
            </div>
        </div>
        <div className="row justify-content-md-center text-white">
            <div className="col-md-auto text-center"><a className="text-white" href="http://www.fs-soft.at/AGB_V205ae.pdf?fileticket=Ea_zSm2xtM0%3d&tabid=55&language=de-DE">Datenschutz &amp; Cookies</a></div>
            <div className="col-md-auto d-none d-md-block">|</div>
            <div className="col-md-auto text-center"><a className="text-white" href="http://www.fs-soft.at/en-us/unternehmen/impressum.html">Impressum</a></div>
            <div className="col-md-auto d-none d-md-block">|</div>
            <div className="col-md-auto text-center">© {new Date().getFullYear()} by FS-Soft IT Consulting GmbH</div>
        </div> 
    </div>
  </footer>
)

export default Footer
