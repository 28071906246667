import React from "react"
import Logo from "./logo"
import { FontAwesomeIcon  } from "@fortawesome/react-fontawesome"

const Header = () => (
  <header>
    <div className="container">
      <nav className="navbar navbar-expand-lg navbar-light">
        <a href="https://www.fs-soft.at/" className="navbar-brand fill-blue"><Logo /></a>
        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>

        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav ml-auto">
            <li className="nav-item">
              <span className="navbar-text d-lg-none d-xl-block">Wir helfen gerne</span>
            </li>
            <li className="nav-item">
              <a className="nav-link text-secondary" href="tel:+43427440888"><FontAwesomeIcon icon="phone" flip="horizontal"/> +43(0) 4274 / 40 888</a>
            </li>
            <li className="nav-item d-none d-lg-block"><span className="navbar-text mx-2">|</span></li>
            <li className="nav-item">
              <span className="navbar-text">Mo-Fr 8:00-18:00</span>
            </li>
            <li className="nav-item d-none d-lg-block"><span className="navbar-text mx-2">|</span></li>
            <li className="nav-item">
              <a className="nav-link text-secondary" href="mailto:office@fs-soft.at">office@fs-soft.at</a>
            </li>
            <li className="nav-item d-none d-lg-block"><span className="navbar-text mx-2">|</span></li>
            <li className="nav-item">
              <a className="nav-link text-secondary" href="https://www.fs-soft.net/support/"><FontAwesomeIcon icon="comments" flip="horizontal"/> Support</a>
            </li>
          </ul>
        </div>
      </nav>
    </div>
  </header>
)

export default Header
