import React from "react"
import Icon from "../images/logo.svg"

const Logo = () => (
    <>
        <Icon />
    </>
)

export default Logo
